import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import moment from 'moment';

import ContentContainer from '../global/contentContainer';
import { Line } from '../utility/line';
import Title from '../text/title';
import Subtitle from '../text/subtitle';
import Textbox from '../text/textbox';
import Gallery from '../images/gallery';
import { ListView } from '../events/listView';

import DummyEvent from '../icons/dummy-event';

const EventsContainer = styled.div`
  margin: 75px 0;

  @media (max-width: 767px) {
    margin: 40px 0;
  }
`;

const Theme = {
  highlightColor: '#00aa90',
  textColor: '#ffffff',
};

const ContainerTheme = {
  backgroundColor: '#d2f5e6',
};

// const Tutors = ({ data }) => {
class Tutors extends React.Component {
  constructor(props) {
    super(props);
    let currentTime = moment();

    this.state = {
      product: [],
      relatedEvents: [],
      activePrice: 'full_price',
      numberOfDummyEvents: 0,
      currentTime: currentTime,
    };
  }

  componentDidMount(props) {
    if (
      this.props.data.allPrismicEvent !== null &&
      this.props.data.allPrismicEvent.edges.length >= 1 &&
      this.props.data.allPrismicEvent.edges[0].node !== null
    ) {
      let formattedRelatedEvents = this.props.data.allPrismicEvent.edges
        .filter(product => {
          let eventDateTime = moment(
            product.node.data.rawDate + ' ' + product.node.data.start_time
          );

          let pastEventCheck = moment(this.state.currentTime).isSameOrAfter(
            eventDateTime
          );

          return pastEventCheck === false;
        })
        .map(event => (event = { node: event.node }));

      this.setState(
        {
          relatedEvents: formattedRelatedEvents,
        },
        () => {
          if (this.state.relatedEvents.length < 4) {
            let numberOfDummyEvents = 4 - this.state.relatedEvents.length;
            this.setState({
              numberOfDummyEvents: numberOfDummyEvents,
            });
          }
        }
      );
    }
  }

  render() {
    let tutor = this.props.data.prismicTutors;

    let dummyEvents = [];
    if (this.state && this.state.numberOfDummyEvents >= 1) {
      for (let i = 0; i < this.state.numberOfDummyEvents; i++) {
        dummyEvents.push(
          <Col col={6} sm={6} lg={3} key={i}>
            <DummyEvent fill={Theme.highlightColor} />
          </Col>
        );
      }
    }

    return (
      <>
        <Helmet>
          <title>{tutor.data.name.text}</title>
          <meta name="title" content={tutor.data.name.text} />
          <meta name="description" content={tutor.data.text.text} />
          <meta
            property="og:url"
            content={
              'https://www.draw-brighton.co.uk' + this.props.location.pathname
            }
          />
          <meta property="og:image" content={tutor.data.image.url} />
          <meta
            property=" og:image:secure_url"
            content={tutor.data.image.url}
          />
          <meta property="og:description" content={tutor.data.text.text} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:locale" content="en" />
          <meta name="twitter:title" content={tutor.data.name.text} />
          <meta name="twitter:description" content={tutor.data.text.text} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={tutor.data.image.url} />
        </Helmet>

        <Row justifyContent="center" css={ContainerTheme}>
          <Col col={12} md={10}>
            <ContentContainer padding={'0 36px'} mobilePadding={'40px 9px'}>
              <Title text={tutor.data.name.text} />
              <Subtitle
                text={tutor.data.role}
                color={Theme.highlightColor}
                mobileMargin={'20px 0 0 0'}
              />
            </ContentContainer>
          </Col>

          {tutor.data.gallery.length >= 1 &&
          tutor.data.gallery[0].gallery_image.url !== null ? (
            <Gallery images={tutor.data.gallery} mobileMargin={'0 0 40px 0'} />
          ) : null}

          <Textbox
            text={tutor.data.text.html}
            moreInformation={`For ${
              tutor.data.name.text
            }'s courses, see below...`}
            moreInformationTextColor={Theme.highlightColor}
            bottomMargin={'0 0 40px 0'}
            mobileBottomMargin={'0 9px 0 9px'}
          />

          <Col col={12}>
            <Line
              margin={'0 36px'}
              mobileMargin={'0 9px'}
              color={Theme.highlightColor}
            />
          </Col>

          <Col col={12}>
            <ContentContainer padding={'0 36px'} mobilePadding={'0 9px'}>
              <EventsContainer>
                <Row>
                  {this.state.relatedEvents.length >= 1 && (
                    <>
                      <ListView
                        allEvents={this.state.relatedEvents}
                        color={Theme.highlightColor}
                        textColor={Theme.textColor}
                      />
                      {this.state.numberOfDummyEvents < 4 && dummyEvents}
                    </>
                  )}
                </Row>
              </EventsContainer>
            </ContentContainer>
          </Col>
        </Row>
      </>
    );
  }
}

export default Tutors;

export const pageQuery = graphql`
  query TutorsBySlug($uid: String!) {
    prismicTutors(uid: { eq: $uid }) {
      uid
      data {
        name {
          text
        }
        role
        image {
          dimensions {
            width
            height
          }
          copyright
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        text {
          html
          text
        }
        gallery {
          gallery_image {
            url
            copyright
            dimensions {
              width
              height
            }
          }
        }
      }
    }
    allPrismicEvent(
      filter: {
        data: { tutor: { uid: { eq: $uid } }, date: { gte: "2024-09-16" } }
      }
      sort: { order: ASC, fields: [data___date, data___start_time] }
    ) {
      edges {
        node {
          uid
          prismicId
          data {
            title {
              text
            }
            eventbrite_link {
              url
            }
            image {
              alt
              fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                srcWebp
                srcSetWebp
              }
            }
            partner_event
            rawDate: date
            date(formatString: "ddd, DD MMM ‘YY")
            rawEndDate: end_date
            end_date(formatString: "ddd, DD MMM ‘YY")
            tutor {
              url
              document {
                ... on PrismicTutors {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            location {
              url
              document {
                ... on PrismicLocation {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            start_time
            finish_time
            full_price
            discounted_price
          }
        }
      }
    }
  }
`;
